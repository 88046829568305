<template>
  <div class="home">
    <table border="10" style="border:5px solid transparent border-collapse:collapse width:100%" >
    <tr> <td style=" padding: 15px width:75%">
    <HelloWorld msg="Our new website is on its way!" /></td> <td style="width:25%">
    <img width="250" height="300" alt="Vue logo" src="../assets/PSMlogo.jpg" /></td></tr>
    </table>
  </div>
</template>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
th, td {
  padding: 30px;
}
</style>
<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    HelloWorld,
  },
};
</script>
