<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p align="left" >
        PediatricSocialWorX will provide the Pediatric Social Work Community a forum for pediatric-specific continuing education to enhance and expand our professional subject matter expertise!<br /><br />

        Your input in building this forum is important.  Please take a few minutes and complete this survey, as it will provide valuable guidance towards building this forum and designing meaningful CEUs for pediatric social work.<br /><br />

        A scan of available online opportunities for social work continuing education was enlightening. It was clear that there are limited online CEUs that truly target the pediatric social workers’ professional educational needs.<br /><br />

        The survey does not ask for your personal contact information; however, I would love to hear from you. Please contact me at: cindy@pediatricsocialworX.com. Thank you for your time and valuable input!! <br /><br />

        <a href="https://qfreeaccountssjc1.az1.qualtrics.com/jfe/form/SV_byflncG20reVQ6a">SURVEY LINK</a>
      </p>    
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
